import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "contactUsObject", "addressObject", "dataAnalyticsAddress", "googleMyBusinessCID"];
var __jsx = React.createElement;
/**
 * @author Harmeet
 */import React from 'react';
import ContactUsCard from '../../ContactUsCard';
import AddressCard from '../../AddressCard';
var ContactCard = function ContactCard(_ref) {
  var className = _ref.className,
    contactUsObject = _ref.contactUsObject,
    addressObject = _ref.addressObject,
    dataAnalyticsAddress = _ref.dataAnalyticsAddress,
    googleMyBusinessCID = _ref.googleMyBusinessCID,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: "contactCard"
  }, addressObject && __jsx(AddressCard, _extends({
    googleMyBusinessCID: googleMyBusinessCID,
    dataAnalytics: dataAnalyticsAddress || {}
  }, addressObject)), contactUsObject && __jsx(ContactUsCard, _extends({}, contactUsObject, {
    dataAnalyticsAddress: dataAnalyticsAddress
  })));
};
ContactCard.defaultProps = {
  className: ''
};
export default ContactCard;