import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "className", "name", "onChange", "label", "labelProps", "fieldProps"];
var __jsx = React.createElement;
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import Label from '../../../atoms/Label';
import Select from '../../../atoms/Select';
import FieldError from '../../../atoms/FieldError';
import styles from './FieldSelect.style';
var FieldSelect = function FieldSelect(_ref) {
  var id = _ref.id,
    className = _ref.className,
    name = _ref.name,
    _onChange = _ref.onChange,
    label = _ref.label,
    labelProps = _ref.labelProps,
    fieldProps = _ref.fieldProps,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(Label, _extends({
    htmlFor: id
  }, labelProps), label), __jsx(Field, _extends({
    name: name,
    render: function render(_ref2) {
      var field = _ref2.field,
        form = _ref2.form;
      var handleChange = form.handleChange;
      return __jsx(Select, _extends({}, others, field, {
        onChange: function onChange(e) {
          handleChange(e);
          if (typeof _onChange === 'function') _onChange(e, form);
        }
      }));
    }
  }, fieldProps)), __jsx(ErrorMessage, {
    name: name,
    component: FieldError
  }));
};
FieldSelect.defaultProps = {
  labelProps: {},
  fieldProps: {},
  onChange: function onChange() {}
};
export default styled(FieldSelect).withConfig({
  componentId: "sc-172j79t-0"
})(["", ";"], styles);
export { FieldSelect as FieldSelectVanilla };