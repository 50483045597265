import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "inheritedStyles", "address", "mapUrlOverride"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Anchor from '../../atoms/Anchor';
import { getAddressURL, getCompleteAddress } from '../../../utils/utilityFunction';
import { linkTargetWindow } from '../../../constants/globalConstants';
import styles from './MapAnchor.style';
var MapAnchor = function MapAnchor(_ref) {
  var children = _ref.children,
    className = _ref.className,
    inheritedStyles = _ref.inheritedStyles,
    address = _ref.address,
    mapUrlOverride = _ref.mapUrlOverride,
    others = _objectWithoutProperties(_ref, _excluded);
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  return __jsx(Anchor, _extends({
    className: "nva-map-anchor"
    // TODO: Add Analytics
    ,
    href: mapUrlOverride && mapUrlOverride.url || addressStringURL,
    title: mapUrlOverride && mapUrlOverride.label || addressString,
    ctaBehavior: mapUrlOverride && mapUrlOverride.action || linkTargetWindow.newWindow,
    hyperlinkType: mapUrlOverride && mapUrlOverride.hyperlinkType
  }, others), children);
};
MapAnchor.defaultProps = {
  inheritedStyles: ''
};
export default styled(MapAnchor).withConfig({
  componentId: "sc-va1eru-0"
})(["", ";"], styles);
export { MapAnchor as MapAnchorVanilla };